import React, { useCallback, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import sentMessageSVG from '../images/message-sent.svg'

export default function ContactForm() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [messageSentSuccess, setMessageSentSuccess] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()

      if (!executeRecaptcha) {
        return
      }

      const result = await executeRecaptcha('contact')

      const data = new FormData()
      data.append('fullname', name)
      data.append('email', email)
      data.append('message', message)
      data.append('recaptcha', result)

      fetch('https://gatsby.voxrec.net/email.php', {
        method: 'POST',
        headers: {},
        body: data,
      })
        .then(res => {
          setName('')
          setEmail('')
          setMessage('')
          if (res.status === 200) {
            setMessageSentSuccess(true)
          }
        })
        .catch(err => console.log(err))
    },
    [executeRecaptcha, email, message, name]
  )

  const handleChange = useCallback(e => {
    e.preventDefault()
    if (e.target.name === 'name') setName(e.target.value)
    if (e.target.name === 'email') setEmail(e.target.value)
    if (e.target.name === 'message') setMessage(e.target.value)
  }, [])

  return (
    <form method="post" onSubmit={handleSubmit} name="VoxRec contact form">
      <label htmlFor="name">
        <input
          type="text"
          name="name"
          id="name"
          required
          placeholder="Ihr Name"
          onChange={handleChange}
          value={name}
        />
      </label>
      <label htmlFor="email">
        <input
          type="email"
          name="email"
          id="email"
          required
          placeholder="Ihre E-Mail-Adresse"
          onChange={handleChange}
          value={email}
        />
      </label>
      <label htmlFor="message">
        <input
          name="message"
          id="message"
          onChange={handleChange}
          required
          placeholder="Nachricht"
          value={message}
        />
      </label>
      <div className="relative ">
        <button
          type="submit"
          className="send-button"
          disabled={executeRecaptcha == null}
        >
          Senden
        </button>
        {messageSentSuccess ? (
          <div className="shadow-lg send-confirmation">
            <img
              src={sentMessageSVG}
              alt="Message sent tick"
              className="mr-4"
            />
            <p className="text-sm text-black bold">
              Vielen Dank für Ihre Nachricht. Wir melden uns in Kürze bei Ihnen!
            </p>
          </div>
        ) : null}
      </div>
    </form>
  )
}
