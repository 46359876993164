import React from 'react'
import topFoldSVGReversed from './illustrations/topFoldSVGReversed.svg'

const About = () => {
  return (
    <section id="about">
      <div className="pb-10 md:flex md:flex-row-reverse md:pb-16 lg:pb-20 about-layout">
        <div className="px-4 md:w-1/2 md:justify-center md:flex md:flex-col">
          <div className="mb-6 md:mb-0 md:max-w-xl lg:pl-20">
            <h2 className="mb-4 heading">
              Hochpräzise Spracherkennung ab nur 0,07€/Min.
            </h2>
            <p className="sub-heading">
              Haben Sie schon mal erlebt, dass Ihnen wichtige Gesprächsdetails oder eine geniale Idee entgangen sind? Ob es sich um ein ereignisreiches Meeting, eine Sprechstunde beim Arzt oder einfach eine kreative Idee handelt - erstellen Sie Sprachmemos und erhalten Sie ein Transkript der Sprachaufnahme zur Durchsicht. Sparen Sie Zeit. Und Geld.
            </p>
          </div>
        </div>
        <div className="hidden md:w-1/2 md:block md:pr-10 img-container">
          <img
            width="663"
            height="404"
            src={topFoldSVGReversed}
            alt="About"
            className="about-img"
            decoding="async"
          />
        </div>
      </div>
    </section>
  )
}

export default About
